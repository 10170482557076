import React from "react"

import imgCompanies from "../../images/database-diagram/companies.png"
import imgFrameworks from "../../images/database-diagram/frameworks.png"
import imgFund from "../../images/database-diagram/fund.png"
import imgIndicators from "../../images/database-diagram/indicators.png"
import imgThemes from "../../images/database-diagram/themes.png"
import { Col, Group } from "../common"

class DatabaseDiagram extends React.Component {
  renderCol(bg, icon, header, text, darkText) {
    return (
      <Col size="fifth" style={{ background: bg }}>
        <img src={icon} />
        <h4>{header}</h4>
        <p className={darkText ? "dark-text" : "light-text"}>{text}</p>
      </Col>
    )
  }

  render() {
    return (
      <div class="database-diagram">
        <Group>
          {this.renderCol(
            "#33353b",
            imgCompanies,
            "42,500 Public Companies",
            "The S-Factor Solution currently provides corporate profile information for over 42,500 public companies world wide. Additional layers of operational level data, geographical revenue exposure, supply chain data, reputational risk data, industry and risk environment, regulatory requirement information have been added with history to formulate a robust analysis of the social risk landscape by company.",
            false
          )}
          {this.renderCol(
            "#e05d33",
            imgThemes,
            "74 Social Impact Themes",
            "ESG without the S is at risk of socialwashing. Our trademark slogan since 2010 has been “From Intentions to Impact”. Trust requires truth. The relevancy of deep-dive social impact criteria prioritizes social risk, mitigating exposure, increasing effectiveness and efficiencies, and returns.",
            false
          )}
          {this.renderCol(
            "#a9a9a9",
            imgFrameworks,
            "45 Frameworks",
            "S-Factor provides the largest set of norms-based screening available in the entire ESG market today. This standardizes the criteria, benchmarking the worlds best-practice, codes, regulations. Peer and industry evaluation throughout supply chains is unparalleled on social and demonstrates the value in social.",
            false
          )}
          {this.renderCol(
            "#d1cece",
            imgIndicators,
            "300+ Indicators",
            "The most effective way to dispel impact / social washing is to learn of the underlying taxonomy behind the criteria. Beware of NLP harvested “tagged” keyword entity scrapping exercises – selling “social data” – this poses the largest threat to the SRI/ESG market today. The S-Factor solution provides over 300 criteria benchmarked to 45 frameworks, requiring thousands of metrics to validate its sources, based on decades of subject expertise.",
            true
          )}
          {this.renderCol(
            "#ded8cd",
            imgFund,
            "Fund + Portfolio mgmt",
            "As a result of covering the entire public equities market, S-Factor has the capacity to evaluate all public funds, ETFs, SRI and Sustainable Impact funds and ESG qualified integrations. Consider this an automated audit across all regulations and source content validation. Learn about your fund's performance strengths ad exposure weaknesses and capitalize on social ROI.",
            true
          )}
        </Group>
      </div>
    )
  }
}

export { DatabaseDiagram }
