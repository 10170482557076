import React, { Component } from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import TopContainer from "../components/TopContainer"
import {
  Group,
  Col,
  Section,
  CtaButton,
  VideoEmbed,
} from "../components/common"

import img from "../images/stock/home1.jpg"
// import img2 from "../images/photo1.jpg"

// import imgBackground from "../images/background.png"
// import imgTestimonials from "../images/testimonials.png"
// import imgTeam from "../images/team.png"
// import imgFund from "../images/fund.png"

// import imgMoneyPlant from "../images/moneyplant.jpg"
import { DatabaseDiagram } from "../components/diagrams/DatabaseDiagram"

import partner1 from "../images/partners/partner1.png"
import partner2 from "../images/partners/partner2.png"
import partner3 from "../images/partners/partner3.png"
import partner4 from "../images/partners/partner4.png"
import partner5 from "../images/partners/partner5.png"
import partner6 from "../images/partners/partner6.png"

class IndexPage extends Component {
  state = {
    blockData: [
      {
        title: "42,500+",
        subtitle: "Public Companies",
        color: "#F05E2A",
      },
      {
        title: "74",
        subtitle: "Social Impact Themes",
        color: "#F37043",
      },
      {
        title: "45",
        subtitle: "Frameworks",
        color: "#F37F58",
      },
      {
        title: "300+",
        subtitle: "Social specific indicators & thousands of metrics",
        color: "#F6906E",
      },
    ],

    partners: [
      {
        img: partner1,
        url: "https://thegiin.org/",
      },
      {
        img: partner2,
        url: "https://www.sasb.org/",
      },
      {
        img: partner3,
        url: "https://eaglealpha.com/",
      },
      {
        img: partner4,
        url: "https://fullyvested.com/",
      },
      {
        img: partner5,
        url: "https://www.cqam.ca/",
      },
      {
        img: partner6,
        url: "https://caasa.ca/",
      },
    ],
  }

  renderPartners() {
    return this.state.partners.map((p, i) => {
      return (
        <a href={p.url} className="partner" target="_blank">
          <img src={p.img} />
        </a>
      )
    })
  }

  render() {
    return (
      <>
        <Layout>
          <SEO
            title="Investment Culture | Social Impact Performance"
            keywords={["Investment Culture", "Social Impact Performance"]}
            description="S-Factor helps in global investment culture that values people over profit and to fulfill the social impact information gap. Contact us today!"
          />
          <div className="page page-home">
            <TopContainer
              titleLines={[["We put the S in ESG", <sup>TM</sup>]]}
              backgroundImage={img}
              isVideo={true}
              mp4={
                "https://firebasestorage.googleapis.com/v0/b/sfactor-website.appspot.com/o/home.mp4?alt=media"
              }
              body="The S Factor Co. provides data and analytics for social impact. Our methodology is centred around one key principle: to demonstrate the value of adding the true litmus for social impact measurement and management to the ESG equation. The ‘E’ and ‘G’ are not ignored; they are simply incomplete without the social lens. "
              ctaText="Request a Product Demo"
              ctaLinkHref={"https://trial.thesfactor.co/auth/sign-up"}
              ctaLinkTarget={"_blank"}
            />

            <Section>
              <Group>
                <Col size="third">
                  <div className="headingAccent" />

                  <h3>Statement</h3>
                </Col>
                <Col size="two-thirds">
                  <p>
                    There are currently{" "}
                    <a
                      href="https://www.ussif.org/fastfacts"
                      style={{ textDecoration: "underline" }}
                      target="_blank"
                    >
                      <em>US$51.4 Trillion in AUM</em>
                    </a>{" "}
                    employing Responsible and Environmental, Social and
                    Governance (“ESG”) strategies around the globe.
                  </p>
                  <p>
                    But the <em>“S”</em> – the social factors in ESG, if
                    present, are based on anecdotal data.
                  </p>
                  <p>
                    The entire market is facing the risk of impact-washing ESG
                  </p>
                  <p>
                    <em>THIS IS AN ENORMOUS PROBLEM.</em>
                  </p>
                  <p>Until Now.</p>
                </Col>
              </Group>
            </Section>

            <Section>
              <Group>
                <Col size="third">
                  <div className="headingAccent" />

                  <h3>Mission</h3>
                </Col>
                <Col size="two-thirds">
                  <p>
                    It is our mission is to fulfil the social impact information
                    gap; by comprehensively monitoring, measuring and reporting
                    on the social impact of public companies globally. This will
                    provide transparent, objective, material, accountable,
                    legitimate and unbiased content to better inform decisions.
                  </p>
                </Col>
              </Group>
            </Section>

            <Section>
              <Group>
                <Col size="third">
                  <div className="headingAccent" />

                  <h3>Vision</h3>
                </Col>
                <Col size="two-thirds">
                  <p>
                    We envision a global investment culture that values people
                    over profit. We envision responsible product life cycles,
                    inclusive of human rights throughout its operations and
                    supply chain. We envision a shift in financially material
                    value recognition for the Social Factors™.
                  </p>
                </Col>
              </Group>
            </Section>

            <Section hasDivider={false}>
              <Group>
                <Col size="third">
                  <div className="headingAccent" />

                  <h3>Purpose</h3>
                </Col>
                <Col size="two-thirds">
                  <p>
                    The specific purpose behind our mission, is to drive value
                    for social impact performance.
                  </p>

                  <Group>
                    <Col size="third">
                      <CtaButton linkTo={"about"} dark={true}>
                        Who we are
                      </CtaButton>
                    </Col>
                    <Col size="third">
                      <CtaButton linkTo={"s-factor"} dark={true}>
                        What we do
                      </CtaButton>
                    </Col>
                  </Group>
                </Col>
              </Group>
            </Section>
            <div style={{ height: 32 }} />

            {/* <Section dark={true}>
              <div style={{ height: 40 }} />

              <Group>
                <Col size="third">
                  <div className="headingAccent" />

                  <h3>We put the S in ESG</h3>
                </Col>
                <Col size="two-thirds">
                  <p style={{ fontSize: 20, lineHeight: 1.4 }}>
                    The S Factor Score is a proprietary method which maps every
                    social indicator possible to a standardized set of themes.
                    Our unique scoring methodology captures material evidence of
                    company’s management and performance in these areas, at
                    every site of operation.{" "}
                  </p>
                  <p style={{ fontSize: 20, lineHeight: 1.4 }}>
                    The MacCormick Social Performance Index “MSPI” marries the S
                    Factor Score + Financial Performance + Controversies &
                    Sentiment ratings, in order to identify the best-in-class
                    for social, financial and public perception returns.{" "}
                  </p>
                  <p style={{ fontSize: 20, lineHeight: 1.4 }}>
                    The S Factor Ratings “ratings” monitors performance in both
                    the score and the index concepts to identify, predict and
                    rate companies risk exposure in social impact issues.
                  </p>
                </Col>
              </Group>
            </Section>

            <Section dark={true} hasDivider={false}>
              <Group>
                <Col size="third">
                  <div className="headingAccent" />
                  <h3>ESG and SRI</h3>
                </Col>
                <Col size="two-thirds">
                  <p style={{ fontSize: 20, lineHeight: 1.4 }}>
                    SRI is an investment discipline that considers ESG criteria
                    to generate long-term competitive financial returns and
                    positive social impact.{" "}
                  </p>
                  <p style={{ fontSize: 20, lineHeight: 1.4 }}>
                    At large, the social metrics and content substantially
                    underrepresented or are non-existent in across the ESG and
                    Impact markets.{" "}
                  </p>

                  <div style={{ height: 20 }} />

                  <img src={imgMoneyPlant} style={{ width: "100%" }} />
                </Col>
              </Group>

              <div style={{ height: 40 }} />
            </Section>

            <Section hasDivider={false}>
              <div className="headingAccent" />

              <h3>Our Company</h3>
              <Group>
                <Col size="third">
                  <div
                    className="square-graphic"
                    style={{ backgroundImage: `url(${imgBackground})` }}
                  />
                  <h4>Background</h4>
                  <p style={{ height: 72 }}>
                    The S Factor Co. is a subsidiary of MacCormick Inc. (est.
                    2009), a Social Impact Management Consulting firm.
                  </p>
                  <CtaButton linkTo={"about"} dark={true}>
                    More About Us
                  </CtaButton>
                  <p />
                </Col>
                <Col size="third">
                  <div
                    className="square-graphic"
                    style={{ backgroundImage: `url(${imgTestimonials})` }}
                  />
                  <h4>Testimonials</h4>
                  <p style={{ height: 72 }}>
                    It is the most comprehensive and systematic rating system
                    the sector has ever seen.
                    <br />
                    -Sir Mark Moody-Stuart
                  </p>
                  <CtaButton linkTo={"about#testimonials"} dark={true}>
                    More About Us
                  </CtaButton>
                  <p />
                </Col>
                <Col size="third">
                  <div
                    className="square-graphic"
                    style={{ backgroundImage: `url(${imgTeam})` }}
                  />
                  <h4>Team</h4>
                  <p style={{ height: 72 }}>
                    The S Factor Co. is led by a world-class team of global
                    advisors who are sector and subject matter experts.
                  </p>
                  <CtaButton linkTo={"about#team"} dark={true}>
                    More About Us
                  </CtaButton>
                  <p />
                </Col>
              </Group>
            </Section> */}
            {/* <Section hasDivider={false}>
              <div
                className="wide-graphic"
                style={{ backgroundImage: `url(${imgFund})` }}
              >
                <h4>The S Factor Fund</h4>
                <h5>Launching Soon</h5>
              </div>
            </Section> */}
          </div>
        </Layout>
      </>
    )
  }
}

export default IndexPage
